import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Box,
  Tooltip,
  styled,
  ButtonProps,
} from "@mui/material";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack"; // Importing from notistack
import axios from "axios";
import ExcelToJsonConverter, {
  ExcelToJsonConverterRef,
} from "../../tools/ExcelToJsonConverterComponent/ExcelToJsonConverter";
import ExcelDataGrid from "../../tools/ExcelDataGridComponent/ExcelDataGrid";
import { useGridApiRef } from "@mui/x-data-grid";
import MiniDrawer from "../../tools/MiniDrawer";
import { purple } from "@mui/material/colors";
import "./Home.css";
import { FaSearch } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { MdOutlineImportExport, MdOutlineRestartAlt } from "react-icons/md";
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { LoadingButton } from "@mui/lab";
import ExcelExportDataGrid from "../../tools/ExcelExportDataGridComponent/ExcelExportDatagrid";
interface InvalidCell {
  key: string;
  status: string;
}

const Home = () => {
  //constantes de state ///////////////////////////////////////////////////////////////////////////
  const [jsonData, setJsonData] = useState<any[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [invalidCells, setInvalidCells] = useState<InvalidCell[]>([]);
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [selectedColindex, setSelectedColindex] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const excelToJsonRef = useRef<ExcelToJsonConverterRef>(null);

  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const apiRef = useGridApiRef();
  const [resetKey, setResetKey] = useState(0);
  const [keyMapping, setKeyMapping] = useState<any[]>([]);
  const updateTriggerRef = useRef<HTMLButtonElement>(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { instance, accounts, inProgress } = useMsal();
  const msalInstance = instance;

  const [widthDrawer, setWidthDrawer] = useState("");

  const expectedColumns = [
    "Code société",
    "Code Article",
    "Libellé 1",
    "Libellé 2",
    "Grp de famille",
    "Famille",
  ];
  const validateColumns = (data: any) => {
    // Get the keys (columns) of the first object in the data
    const uploadedColumns = Object.keys(data[0]);

    // Find missing columns
    const missingColumns = expectedColumns.filter(
      (col) => !uploadedColumns.includes(col)
    );

    // Find unexpected columns
    const unexpectedColumns = uploadedColumns.filter(
      (col) => !expectedColumns.includes(col)
    );

    // Check if there are any missing or unexpected columns
    if (missingColumns.length > 0) {
      const errorMessages = [];

      if (missingColumns.length > 0) {
        errorMessages.push(`Missing columns: ${missingColumns.join(", ")}`);
      }

      // if (unexpectedColumns.length > 0) {
      //   errorMessages.push(
      //     `Unexpected columns: ${unexpectedColumns.join(", ")}`
      //   );
      // }

      throw new Error(errorMessages.join("; "));
    }
  };

  ////fonction manipulations /update des données ///////////////////////////////////////////////////////////////
  useEffect(() => {
    // This effect runs when resetKey changes
    if (resetKey !== 0) {
      // Reset state here
      setJsonData([]);
      setUploadStatus("");
      setInvalidCells([]);
      setSelectedRow(0);
      setSelectedColumn(null);
    }
  }, [resetKey]);
  const tokenRequest = {
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: true, // Force a token refresh
  };
  const isDisabled = invalidCells.length > 0;
  const buttonStyle = {
    color: isDisabled ? "gray" : "black",
    background: isDisabled ? "#FFEA7F" : "#FFD100", // Lighter shade when disabled
    cursor: isDisabled ? "not-allowed" : "pointer",
  };
  const indexRef = useRef({ index1: 0, index2: 0 });

  useEffect(() => {
    indexRef.current.index1 = selectedRow;
    indexRef.current.index2 = selectedColindex;
  }, [selectedRow, selectedColindex]);
  const handleReset = () => {
    // Increment the resetKey to force remount of the component
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target || !event.target.files) {
      console.log("No file selected or file selection was canceled.");
      return;
    }

    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected or file selection was canceled.");
      return;
    }

    console.log("New file selected:", file);
    // Add logic here to replace the old file or handle the file upload
  };

  const getToken = async (account: AccountInfo) => {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...tokenRequest,
        account,
      });
      console.log(response);
      return response.idToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // If interaction is required, fallback to an interactive method
        const response = await msalInstance.acquireTokenPopup(tokenRequest);
        console.log(response);

        return response.idToken;
      } else {
        console.log("error token ");
        throw error;
      }
    }
  };
  // Function to handle snackbar
  const handleSnackbar = (message: any, variant: any) => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 3000,
      preventDuplicate: true,
    });
  };
  const handleInvalidCellsChange = (invalidCells: {
    [key: string]: string;
  }) => {
    const invalidCellsArray: InvalidCell[] = Object.entries(invalidCells).map(
      ([key, status]) => ({ key, status })
    );
    setInvalidCells(invalidCellsArray);
  };

  const handleJsonExport = (data: any[]) => {
    validateColumns(data);

    const jsonDataWithIds = data.map((obj, index) => ({
      ...obj,
      id: index + 1,
    }));
    setJsonData(jsonDataWithIds);

    setKeyMapping(jsonDataWithIds);
  };

  const handleRowUpdate = (updatedRow: any) => {
    setJsonData((prevData) =>
      prevData.map((row) => (row.id === updatedRow.id ? updatedRow : row))
    );
  };

  const handleCellClick = (key: string) => {
    // Extract row and column from the key
    const [row, column] = key.split("-");
    const columnKeys = Object.keys(keyMapping[0]);
    const colIndex = columnKeys.indexOf(column);

    // Set selected row and column for your component state
    setSelectedRow(parseInt(row));
    setSelectedColumn(column);
    setSelectedColindex(colIndex);

    // Using the API to scroll to the cell
    apiRef.current.scrollToIndexes({
      rowIndex: parseInt(row),
      colIndex: colIndex,
    });

    // Use a timeout to ensure the grid has rendered the cell after scrolling
    setTimeout(() => {
      // Now that the cell should be rendered, try to get the cell element
      const cellElement = apiRef.current.getCellElement(parseInt(row), column);
      if (cellElement) {
        // Optionally, scroll into view if necessary
        cellElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });

        // Start editing the cell
        apiRef.current.startCellEditMode({ id: row, field: column });
      } else {
        console.error(
          `Cell at row ${row} and column ${column} is not found after scroll.`
        );
      }
    }, 300); // Adjust delay as needed based on grid rendering speed
  };

  const transformObject = (obj: any) => {
    return {
      VATCode: obj.VATCode,
      LSArticle: obj.LSArticle || "O",
      MPlusOneConsommation: obj.MPlusOneConsommation || 0.0,
      CVSU1ToCVSRFU1: obj.CVSU1ToCVSRFU1 || 0.0,
      CVSU2ToCVSRFU2: obj.CVSU2ToCVSRFU2 || 0.0,
      ArticleSoc: obj.ArticleSoc,
      ArticleCode: obj.ArticleCode.toString(), // Ensure it's a string
      ArticleName: obj.ArticleName,
      ArticleName2: obj.ArticleName2 || "",
      Group: obj.Group,
      Family: obj.Family,
      BuyingUnity: obj.BuyingUnity,
      StockUnity: obj.StockUnity,
      SalesUnity: obj.SalesUnity,
      UnityBuyingOnSales: obj.UnityBuyingOnSales || 1.0,
      UnityStockOnSales: obj.UnityStockOnSales || 1.0,
      WeightByUnity: obj.WeightByUnity || 0.0,
      Provider: obj.Provider,
      ReferenceProvider: obj.ReferenceProvider || "",
      Gencod: obj.Gencod || "",
      Gencod2: obj.Gencod2 || "",
      Gencod3: obj.Gencod3 || "",
      CoefficientA: obj.CoefficientA || "2.0000",
      CoefficientB: obj.CoefficientB || 1.02,
      CoefficientC: obj.CoefficientC || 1.0,
      CoefficientD: obj.CoefficientD || 1.0,
      CoefficientE: obj.CoefficientE || 1.0,
      CoefficientF: obj.CoefficientF || 1.0,
      CoefficientG: obj.CoefficientG || 1.0,
      CoefficientH: obj.CoefficientH || 1.0,
      ModifiedBy: obj.ModifiedBy || "",
      ModifiedOn: obj.ModifiedOn || "",
      Lot: obj.Lot || "N",
      OnInternet: obj.OnInternet || "N",
      PacketForSale: obj.PacketForSale || 0.0,
      Packaged: obj.Packaged || "N",
      StockCm: obj.StockCm || "N",
      BuyingCentralCode: obj.BuyingCentralCode || "",
      ExpiryPeriod: obj.ExpiryPeriod || "N",
      HazardousMaterial: obj.HazardousMaterial || "N",
      FreezingProduct: obj.FreezingProduct || "N",
      PackageLength: obj.PackageLength || 0.0,
      PackageWidth: obj.PackageWidth || 0.0,
      PackageHeight: obj.PackageHeight || 0.0,
      NbPackagePerLayer: obj.NbPackagePerLayer || 0.0,
      NbLayerPerPalette: obj.NbLayerPerPalette || 0.0,
      LayerHeight: obj.LayerHeight || 0.0,
      Bath: obj.Bath || "N",
      Caliber: obj.Caliber || "N",
      Supplier: obj.Supplier,
      AnnualRequestFixed: obj.AnnualRequestFixed || 12.0,
      SupplierDelay: obj.SupplierDelay || 29,
      PrevisionMethod: obj.PrevisionMethod || "1",
      DelayBeforDLUO: obj.DelayBeforDLUO || 0,
      PaletteLength: obj.PaletteLength || 0.0,
      PaletteWidth: obj.PaletteWidth || 0.0,
      PaletteHeight: obj.PaletteHeight || 0.0,
      PaletteWeight: obj.PaletteWeight || 0.0,
      UnityCode1: obj.UnityCode1 || "",
      UnityCoeff1: obj.UnityCoeff1 || "",
      UnityCode2: obj.UnityCode2 || "",
      UnityCoeff2: obj.UnityCoeff2 || "",
      NumberOfItemsPerPackage: obj.NumberOfItemsPerPackage || 0.0,
      BuyingMultiple: obj.BuyingMultiple || 1,
      Conditioning: obj.Conditioning || 0,
      ConditioningPalette: obj.ConditioningPalette || 0,
      BuyingPrices: {
        TRSOnUnityAmmount: obj.TRSOnUnityAmmount || 0.0,
        TPUnity: obj.TPUnity || "",
        RFACoeff: obj.RFACoeff || 0.0,
        GrossBuyingPrice: obj.GrossBuyingPrice || 0.0,
        Reduction1: obj.Reduction1 || 0.0,
        PercentOnValueReduction1: obj.PercentOnValueReduction1 || "",
        Reduction2: obj.Reduction2 || 0.0,
        PercentOnValueReduction2: obj.PercentOnValueReduction2 || "",
        Reduction3: obj.Reduction3 || 0.0,
        PercentOnValueReduction3: obj.PercentOnValueReduction3 || "",
        NetBuyingPrice: obj.NetBuyingPrice || 0.0,
        BasePrice: obj.BasePrice || 0.0,
        RoundedBuyingPrice: obj.RoundedBuyingPrice || "",
        RoundedGrossPrice: obj.RoundedGrossPrice || "",
        CostPrice: obj.CostPrice || 0.0,
        RoundedCostPrice: obj.RoundedCostPrice || "",
        Percentage: obj.Percentage || "",
      },
      SellingPrices: {
        SalesPriceWithoutTaxesA: obj.SalesPriceWithoutTaxesA || 0.02,
        SalesPriceWithoutTaxesB: obj.SalesPriceWithoutTaxesB || 0.01,
        SalesPriceWithoutTaxesC: obj.SalesPriceWithoutTaxesC || 0.01,
        SalesPriceWithoutTaxesD: obj.SalesPriceWithoutTaxesD || 0.01,
        SalesPriceWithoutTaxesE: obj.SalesPriceWithoutTaxesE || 0.01,
        SalesPriceWithoutTaxesF: obj.SalesPriceWithoutTaxesF || 0.01,
        SalesPriceWithoutTaxesG: obj.SalesPriceWithoutTaxesG || 0.01,
        SalesPriceWithoutTaxesH: obj.SalesPriceWithoutTaxesH || 0.01,
        MinimalPrice: obj.MinimalPrice || 0.0,
      },
      BuyingPrices_LENGTH: obj.BuyingPrices_LENGTH || 0,
      SellingPrices_LENGTH: obj.SellingPrices_LENGTH || 0,
    };
  };

  const handleUploadJSON = async () => {
    console.log("jsonData", jsonData);
    const dataWithoutId = jsonData.map((obj) => {
      // Clone each object to avoid mutating the original data
      const newObj = { ...obj };

      // Remove the id property from each object
      delete newObj.id;
      const keyMapping: any = {
        "Code société": "ArticleSoc",
        "Code Article": "ArticleCode",
        "Libellé 1": "ArticleName",
        "Libellé 2": "ArticleName2",
        "Grp de famille": "Group",
        Famille: "Family",
        UA: "BuyingUnity",
        US: "StockUnity",
        UV: "SalesUnity",
        "US/UA": "UnityBuyingOnSales",
        "US/UV": "UnityStockOnSales",
        FRN: "Provider",
        "Ref FRN": "ReferenceProvider",
        Gencod: "Gencod",
        "Gencod 2": "Gencod2",
        "Gencod 3": "Gencod3",
        "prix achat brut": "GrossBuyingPrice",
        "Remise 1": "Reduction1",
        "Valeur pourcent (V/P)": "PercentOnValueReduction1",
        "Remise 2": "Reduction2",
        "Valeur pourcent": "PercentOnValueReduction2",
        "Remise 3": "Reduction3",
        "Valeur pourcent _v": "PercentOnValueReduction3",
        "Prix d'achat net": "NetBuyingPrice",
        "Prix de base": "BasePrice",
        "Prix vente barème A": "SalesPriceWithoutTaxesA",
        B: "SalesPriceWithoutTaxesB",
        C: "SalesPriceWithoutTaxesC",
        D: "SalesPriceWithoutTaxesD",
        E: "SalesPriceWithoutTaxesE",
        F: "SalesPriceWithoutTaxesF",
        G: "SalesPriceWithoutTaxesG",
        H: "SalesPriceWithoutTaxesH",
        "Coef A": "CoefficientA",
        "Coef B": "CoefficientB",
        "Coef C": "CoefficientC",
        "Coef D": "CoefficientD",
        "Coef E": "CoefficientE",
        Coef_F: "CoefficientF",
        "Coef G": "CoefficientG",
        "Coef H": "CoefficientH",
        Qui: "ModifiedBy",
        Quand: "ModifiedOn",
        "Arrondie PXA": "RoundedBuyingPrice",
        "Arrondie PXB": "RoundedGrossPrice",
        "Prix de revient": "CostPrice",
        "Arrondie Prix de revient": "RoundedCostPrice",
        "cou tp": "TRSOnUnityAmmount",
        "ls cmem": "Lot",
        tva: "VATCode",
        ecommerce: "OnInternet",
        "paquet a la vente": "PacketForSale",
        "Code Centrale achat": "BuyingCentralCode",
        "LS GXO": "LSArticle",
        DLUO: "ExpiryPeriod",
        Dangereux: "HazardousMaterial",
        "Craint le gele": "FreezingProduct",
        "Longueur ": "PackageLength",
        Largeur: "PackageWidth",
        Hauteur: "PackageHeight",
        "NB COLIS PAR COUCHE": "NbPackagePerLayer",
        "COUCHE PAR PAL": "NbLayerPerPalette",
        "Hauteur Couche": "LayerHeight",
        Bain: "Bath",
        Calibre: "Caliber",
        Approvisioinneur: "Supplier",
        "Vente annuelle": "AnnualRequestFixed",
        "Delai FRN": "SupplierDelay",
        "méthode delai": "PrevisionMethod",
        "Longeur palette": "PaletteLength",
        "largeur palette": "PaletteWidth",
        "Hauteur palette": "PaletteHeight",
        "Poids palette": "PaletteWeight",
        CVSU1: "UnityCode1",
        CVSRFU1: "UnityCoeff1",
        CVSU1UR: "CVSU1ToCVSRFU1",
        CVSU2: "UnityCode2",
        CVSRFU2: "UnityCoeff2",
        CVSU2UR: "CVSU2ToCVSRFU2",
        Conditionnement: "ConditioningPalette",
        "PCB GXO": "NumberOfItemsPerPackage",
        "Multiple d'achat": "BuyingMultiple",
        ARANBP: "Conditioning",
        "delai avant benne DLUO": "DelayBeforDLUO",
        Colisée: "Packaged",
        "Stock CM": "StockCm",
        "Prix plancher": "MinimalPrice",
        "Poids/U": "WeightByUnity",
      };

      // Iterate over the keys of the object
      Object.keys(newObj).forEach((key) => {
        // If the key exists in the mapping and the new key is different from the old key
        if (keyMapping[key]) {
          const newKey = keyMapping[key];

          if (newKey !== key) {
            newObj[newKey] = newObj[key];
            delete newObj[key]; // Delete the old key only if they differ
          }
        }
      });
      delete newObj["Libéllé exact"];
      delete newObj["ARTDAF"];
      delete newObj["Ancien Code"];
      delete newObj["UNITE TP"];
      delete newObj["valeur Ou %"];
      delete newObj["Valeur Pourcent"];
      delete newObj["COEF RFA"];
      delete newObj["Plus gros Conditionnement"];
      delete newObj["Plus petit Conditionnement"];
      delete newObj["Ratio Conditionnement"];
      delete newObj["Prévision M+1"];

      return transformObject(newObj);
    });

    const finalData = {
      articles: dataWithoutId,
      articles_LENGTH: dataWithoutId.length,
    };
    delete axios.defaults.headers.post["Content-Type"];
    let token = await getToken(instance?.getAllAccounts()[0]);
    axios.defaults.headers.common[
      "Authorization"
      //  ] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik1HTHFqOThWTkxvWGFGZnBKQ0JwZ0I0SmFLcyJ9.eyJhdWQiOiJlNTk0Zjg1ZC04YzljLTQ3YzEtOGM3ZC0xMGYxMGNlNWYwZTIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vN2VlZTYyYTEtMDA1Mi00YWU2LTlkNzUtMzJhYTVlOWM1NzMzL3YyLjAiLCJpYXQiOjE3MTk0ODk2MjUsIm5iZiI6MTcxOTQ4OTYyNSwiZXhwIjoxNzE5NDkzNTI1LCJuYW1lIjoiSnVsaWVuIEzDqWdlciIsIm5vbmNlIjoiMDE5MDU5OTUtZjhhMi03NGU4LWFkNTctZjc3M2I0Y2MyMDYyIiwib2lkIjoiMzc5NTNlZDEtYTI3Ny00NDRkLTkwMTItY2NkZjFiZDAzZmFjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiamxlZ2VyQHRvdXRmYWlyZS5mciIsInJoIjoiMC5BVWNBb1dMdWZsSUE1a3FkZFRLcVhweFhNMTM0bE9XY2pNRkhqSDBROFF6bDhPSU5BUzQuIiwic3ViIjoiUFluZzdVVWhMeDZ1VW9nOGdLLWdGOGptYUdpQnVXeXJfdlNhcm1pWXZHdyIsInRpZCI6IjdlZWU2MmExLTAwNTItNGFlNi05ZDc1LTMyYWE1ZTljNTczMyIsInV0aSI6IjZvRFBaZzNlMzB1T3RoMkNxLXU3QUEiLCJ2ZXIiOiIyLjAifQ.dzQEgJj4KrUxlcGDuZ5maE7nOaNw-0FxlU1Gn6qnHZju7xI2xD8tp7OFyNqJkNzNJ-TS6gE4qKaQzMIdbFo5oG0b8svC8ks7atM7QwpKUgqGjHZ70opLXVzJvByeSgWvufPSmzyHq19BjUrIKO09MDKXq5y7-YK-IXeQZMix2WLhdUv7B2ZKVEvifubYPFj0m9M4xzqHdAWeF9aacDfjE-zACGy0n8cSUZbIbrE3tM_y5ALlBO71KjwnvNxRXXkvSdwx1P1CqI63wd98hgGjv6W4GB5764XQcG3aVMpnshzxgzH694ElQSnlMYPv5YKQXENQZw3jn3ItLG0HECKIjw
      //`;
    ] = `Bearer ${token ?? ""}`;
    //Appel de l'api pour poster le tableau json
    setLoading(true);
    axios
      .post(
        "https://api.staging.gcp.toutfaire.com/v1/gw/v1/apitools/productinjector",
        finalData
      )

      .then((res: any) => {
        setLoading(false);
        console.log("res", res);
        setUploadStatus("Data uploaded successfully.");
        if (res.data.out_data.errorArticles.length != 0) {
          //cas d'erreurs
          res.data.out_data.errorArticles.map((error: any) => {
            handleSnackbar(
              "Erreur lors de l'envoi des données pour l'article : " +
                error.errorArticle.ArticleCode +
                " " +
                error.errorMessage,
              "error"
            );
          });
        } else {
          console.log("testSucess");
          handleReset();
          handleSnackbar("Articles insérés", "success");
        }
        console.log("testSuccess2");
      })
      .catch(() => {
        setLoading(false);
        setUploadStatus("Erreur sur l'envoi des données.");
        handleSnackbar("Erreur lors de l'envoi des données.", "error");
      });
  };

  const handleCloseSnackbar = () => {
    setUploadStatus("");
  };

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#FFD100",
    "&:hover": {
      backgroundColor: "#FFD100",
    },
    borderRadius: "32px",
    textTransform: "none",
  }));

  const handleButtonClick = () => {
    console.log("ExcelToJsonConverter ref:", excelToJsonRef.current);
    if (excelToJsonRef.current) {
      console.log("Triggering file input");
      excelToJsonRef.current.triggerFileInput();
    } else {
      console.log("Ref is null or undefined");
    }
  };
  return (
    <MiniDrawer width={widthDrawer} setWidth={setWidthDrawer}>
      <Box style={{ flexGrow: 1, padding: "85px 0px 0px 32px" }}>
        <div style={{ flexGrow: 1, alignItems: "center", width: widthDrawer }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Accueil
            </Link>
            <Typography color="text.primary">Ajout d'articles</Typography>
          </Breadcrumbs>
          <Typography
            variant="h3"
            style={{
              margin: "10px 10px 10px 0",
              fontWeight: "bold",
              fontSize: "32px",
            }}
          >
            Ajout d'articles
          </Typography>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ExcelToJsonConverter
              ref={excelToJsonRef}
              onJsonExport={handleJsonExport}
            />

            {jsonData.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <Tooltip
                      title={
                        invalidCells.length > 0 ? "Vérifier votre fichier" : ""
                      }
                    >
                      <span>
                        {/* <ColorButton
                        onClick={handleUploadJSON}
                          disabled={invalidCells.length > 0}
                        >        variant="contained"
                    
                          Envoyer Mon Fichier
                        </ColorButton> */}

                        <LoadingButton
                          onClick={() => {
                            handleUploadJSON();
                          }}
                          loading={loading}
                          loadingPosition="end"
                          style={buttonStyle}
                          sx={{ maxHeight: "40px" }}
                          disabled={invalidCells.length > 0}
                          endIcon={<IoSend />}
                          variant="text"
                        >
                          Ajouter les articles&nbsp;
                        </LoadingButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => handleButtonClick()}
                      style={{ background: "#FFD100", color: "black" }}
                    >
                      Soumettre un nouveau fichier&nbsp;
                      <MdOutlineRestartAlt />
                    </Button>
                  </Grid>
                  {invalidCells.length === 0 && (
                    <Grid item>
                      <ExcelExportDataGrid rows={jsonData} />
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </div>
          {invalidCells.length > 0 && (
            <Box
              mt={2}
              mb={2}
              marginRight={"25px"}
              p={2}
              bgcolor="#EF5350"
              color="white"
              borderRadius={1}
            >
              <Typography variant="h6">Liste d'erreurs:</Typography>
              <div>
                {Array.from(
                  new Set(invalidCells.map((cell) => cell.status))
                ).map((status) => (
                  <div key={status}>
                    <Typography variant="subtitle1" gutterBottom>
                      -{status}
                    </Typography>
                    <ul>
                      {invalidCells
                        .filter((cell) => cell.status === status)
                        .map(
                          (cell, index) => (
                            console.log("test", cell),
                            (
                              <li
                                key={index}
                                onClick={() => handleCellClick(cell.key)}
                                style={{ cursor: "pointer" }}
                                className="hover-grey"
                              >
                                Cellule n°{cell.key}
                              </li>
                            )
                          )
                        )}
                    </ul>
                  </div>
                ))}
              </div>
            </Box>
          )}
          <Box marginRight={"25px"} paddingBottom={"20px"}>
            <ExcelDataGrid
              data={jsonData}
              onRowUpdate={handleRowUpdate}
              onInvalidCellsChange={handleInvalidCellsChange}
              selectedColumn={selectedColumn}
              selectedRow={selectedRow}
              apiRef={apiRef}
              index1={selectedRow}
              index2={selectedColindex}
              title="Ajout d'articles"
            />
          </Box>
        </div>
      </Box>
    </MiniDrawer>
  );
};

export default Home;
