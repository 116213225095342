import { Button } from "@mui/material";
import { MdOutlineImportExport } from "react-icons/md";
import { utils as XLSXUtils, writeFile as XLSXWriteFile } from "xlsx";

const ExcelExportDataGrid = (rows: any) => {
  const exportToExcel = () => {
    console.log("rows ", rows);

    // Ensure rows.rows exists and is an array
    if (Array.isArray(rows?.rows)) {
      // Map through rows and safely delete the 'id' if it exists
      const data = rows.rows.map((obj: any) => {
        const { id, ...rest } = obj; // Use destructuring to remove 'id' safely
        return rest; // Return the object without the 'id' property
      });

      const ws = XLSXUtils.json_to_sheet(data);
      const wb = XLSXUtils.book_new();

      XLSXUtils.book_append_sheet(wb, ws, "Feuille 1");
      XLSXWriteFile(wb, "Export_Data.xlsx");
    } else {
      console.error("Invalid data: rows.rows is undefined or not an array");
    }
  };
  return (
    <Button
      onClick={() => exportToExcel()}
      style={{ background: "#FFD100", color: "black" }}
    >
      Exporter le tableau&nbsp;
      <MdOutlineImportExport />
    </Button>
  );
};

export default ExcelExportDataGrid;
