import * as React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import bg from "../../assets/bg_login.png";
import logo from "../../assets/logo_tout_faire.svg";
import { InputLabel } from "@mui/material";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import { PublicClientApplication } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Define props interface
interface SignInProps {
  /// onRoleRetrieved: (role: string) => void; // Callback function to pass user role to parent
}

const SignInSide: React.FC<SignInProps> = (
  {
    // onRoleRetrieved,
  }
) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  console.log(instance.getTokenCache());
  // console.log(instance.getAllAccounts()[0].username);
  console.log(instance.getActiveAccount());
  const isAuthenticated = useIsAuthenticated();
  console.log(isAuthenticated);
  console.log("instance");
  // React.useEffect(() => {
  //   const fetchUserRoles = async () => {
  //     try {
  //       // Make API request to fetch user roles
  //       const response = await fetch("/api/user/roles", {
  //         method: "GET",
  //         headers: {
  //           // Add any necessary headers, such as authorization token
  //           // Example: Authorization: `Bearer ${accessToken}`
  //         },
  //       });
  //       if (response.ok) {
  //         const roles = await response.json();
  //         // Assuming roles is an array of strings
  //         if (roles.length > 0) {
  //           // Pass the first role to the parent component
  //          // onRoleRetrieved(roles[0]);
  //         }
  //       } else {
  //         console.error("Failed to fetch user roles:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user roles:", error);
  //     }
  //   };

  //   fetchUserRoles();
  // }, [onRoleRetrieved]);
  const fetchRole = async (account: any, token: any) => {
    try {
      // const token = await getToken(accounts[0]);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const response = await axios.get(
        `https://api.prod.gcp.toutfaire.com/v1/gw/v1/apitools/users/${account.username}`
      );
      response.data.out_data.PriceComparatorRights.databases.forEach(
        (d: any) => {
          if (d.clientName === "") d.clientName = d.databaseName;
        }
      );
      let arrayRights = [];
      //setRoleError(response.data.out_data.PriceComparatorRights.databases);
      if (
        response.data.out_data.PriceComparatorRights.databases.length === 0 ||
        response.data.out_data.userRole === ""
      )
        localStorage.setItem("RightPriceComparator", "false");
      if (response.data.out_data.PriceComparatorRights.accessRights === "O") {
        arrayRights.push("Comparator/ComparePrices");
      }
      if (response.data.out_data.AddProductsRights.accessRights === "O") {
        arrayRights.push("Comparator/AddArticles");
      }
      if (response.data.out_data.EditProductRights.accessRights === "O") {
        arrayRights.push("Comparator/EditProduct");
      }
      if (response.data.out_data.ImportLadRights.accessRights === "O") {
        arrayRights.push("Ecommerce/ImportLAD");
      }
      localStorage.setItem("rights", JSON.stringify(arrayRights));
      console.log("setItems", localStorage.getItem("rights"));
    } catch (error) {
      console.error("Error fetching role:", error);
      //setRoleError([]);
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      console.log(isAuthenticated);
      if (!isAuthenticated) {
        await instance
          .loginPopup()
          .then(async (res: any) => {
            console.log("Authentication successful:", res);
            fetchRole(res.account, await res.account.idToken);

            navigate("/Accueil");
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else {
        console.log("Authentication successful:");

        console.log(instance.getActiveAccount());
        //Get user ROle
        // Redirect to home page after successful login
      }
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundColor: "#FFD100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="Logo" />
            <Typography component="h1" variant="h5">
              Connexion
            </Typography>
            <Box
              component="form"
              noValidate
              sx={{ mt: 1 }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputLabel
                htmlFor="email"
                style={{ fontWeight: "bold" }}
              ></InputLabel>
              <Button
                variant="contained"
                color="primary"
                startIcon={<MicrosoftIcon />}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  margin: "8px 0px",
                  width: "fit-content",
                }}
                onClick={handleMicrosoftLogin}
              >
                Connexion avec Microsoft
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "right",
            transform: "rotateY(180deg)",
          }}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default SignInSide;
