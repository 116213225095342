import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AccountInfo } from "@azure/msal-browser";
import store from "./redux/store";
import Login from "./components/pages/Login";
import { SnackbarProvider } from "notistack";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { routesComponent } from "./Routes";
import axios from "axios";

interface Routes {
  [key: string]: Route;
}

interface Route {
  path?: string;
  icon?: JSX.Element;
  component?: React.FC;
  subRoutes?: Routes;
}

const isAdmin = () => {
  // Implement your logic to check if the user is an admin
  return true;
};

const App: React.FC = () => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const [routes, setRoutes] = useState<Routes>({});

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && accounts.length > 0) {
      const account: AccountInfo = accounts[0];

      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then(async (response: any) => {
          console.log("response", response);
          // fetchRole(accounts, await response.idToken);
          // Use the response access token
        })
        .catch((error: any) => {
          // Handle token acquisition error
        });

      // Mock role retrieval, replace with actual logic
      const role = "ComparatorEcommerce"; // This should be retrieved based on the authenticated user
      setUserRole(role);
    }
  }, [isAuthenticated, accounts, instance]);

  const getDefaultRoute = () => {
    if (!userRole) return "/"; // Default to login if user role is not yet set

    if (userRole.includes("Comparator")) {
      return "/comparingPrices";
    } else if (userRole.includes("Ecommerce")) {
      return "/importLAD";
    } else {
      return "/home"; // Fallback to a safe default route
    }
  };

  const renderRoutes = (routes: any) => {
    return Object.keys(routes).map((routeName: string) => {
      const { path, component: Component, subRoutes } = routes[routeName];
      return (
        <React.Fragment key={path}>
          {/* Authentication check inside the route rendering */}
          <Route
            path={path}
            element={
              isAuthenticated ? (
                <Component />
              ) : (
                <Navigate to="/" /> // Redirect to login if not authenticated
              )
            }
          />
          {subRoutes && renderRoutes(subRoutes)}
        </React.Fragment>
      );
    });
  };

  return (
    <SnackbarProvider>
      <Provider store={store}>
        <Router>
          <Routes>
            {/* Render the dynamic routes */}
            {renderRoutes(routesComponent)}

            {/* Main route, redirect if not authenticated */}
            <Route
              path="/"
              element={
                isAuthenticated ? <Navigate to={"/Accueil"} /> : <Login />
              }
            />
            {/* Catch-all route for anything not defined */}
            <Route
              path="*"
              element={
                isAuthenticated ? (
                  <Navigate to={getDefaultRoute()} />
                ) : (
                  <Login />
                )
              }
            />
          </Routes>
        </Router>
      </Provider>
    </SnackbarProvider>
  );
};

export default App;
